<template>
  <ul :style="UsersAvatarsStyles" class="UsersAvatars">
    <li
      v-for="(user, index) in users"
      :key="user.id" :style="UsersAvatarStyles(index)"
      class="UsersAvatars__item"
    >
      <UserAvatar :user="user" :size="size" />
    </li>
  </ul>
</template>

<script>
import UserAvatar from '@/components/ui/UserAvatar';

export default {
  name: 'UsersAvatars',
  components: {
    UserAvatar,
  },

  props: {
    users: {
      type: Array,
      required: true,
    },
    size: {
      type: Number,
      default: 40,
    },
  },
  computed: {
    // Получение стилей для списка аватаров
    UsersAvatarsStyles() {
      return {
        'width': `${this.size * this.users.length / 2 + this.size / 2}px`,
      };
    },
  },

  methods: {
    // Получение стилей для аватара
    UsersAvatarStyles(index) {
      return {
        'z-index': this.users.length - index,
        'transform': `translateX(-${this.size / 2 * index}px)`,
      };
    },
  },
}
</script>

<style lang="scss" scoped>
.UsersAvatars {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}
</style>
