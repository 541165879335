<template>
  <div class="UsersAvatar" :class="UsersAvatarClass">
    <img
      class="UsersAvatar__image"
      :src="user.logo"
      alt="Аватар пользователя"
    />
  </div>
</template>

<script>
export default {
  name: 'UserAvatar',

  props: {
    user: {
      type: Object,
      required: true,
    },
    size: {
      type: Number,
      default: 40,
    },
  },
  computed: {
    // Класс
    UsersAvatarClass() {
      return {
        'UsersAvatar--size-12': this.size === 12,
        'UsersAvatar--size-18': this.size === 18,
        'UsersAvatar--size-24': this.size === 24,
        'UsersAvatar--size-40': this.size === 40,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.UsersAvatar {
  border: 1px solid #BABABA;
  border-radius: 50%;

  // Изображение
  &__image {
    max-width: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  // Модификаторы
  &--size {
    &-12 {
      width: 12px;
      height: 12px;
    }

    &-18 {
      width: 18px;
      height: 18px;
    }

    &-24 {
      width: 24px;
      height: 24px;
    }

    &-40 {
      width: 40px;
      height: 40px;
    }
  }
}
</style>
